import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'

import StripGraphic from './Strip'
import ProfileGraphic from './Profile'
import OptionGraphic from './Option'

export default function Step({ steps, setSteps, stepIndex, stepSelect, data, attributeInfo, selectedPriceList }) {
	const step = steps[stepIndex]
	const [collapsed, setCollapsed] = useState(step && step.collapsed === 1)
	// const collapsed = step.collapsed

	switch (step.type) {
		case 'select':
			switch (step.slug) {
				case 'led_strips':
					return (
						<div className={`step`}>
							<h1
								className="step-title clickable"
								onClick={() => {
									setCollapsed(!collapsed)
								}}
							>
								{step.name}
								{typeof step.collapsed === 'number' && (
									<div className={`step-collapse-icon ${collapsed ? 'collapsed' : ''}`} style={{ fontSize: '28px' }}>
										<FontAwesomeIcon icon={faAngleDoubleDown} />
									</div>
								)}
							</h1>

							<div className={`step-content-container ${collapsed ? 'collapsed' : ''}`}>
								<StripGraphic
									strips={[...(step.extra || []), ...((data && data.products && data.products[step.slug]) || [])]}
									stepSelect={(strip, value) => stepSelect(strip, value, stepIndex)}
									selected={step.selected}
									collapsed={collapsed}
									setCollapsed={setCollapsed}
									attributeInfo={attributeInfo}
									selectedPriceList={selectedPriceList}
									data={data}
								/>
							</div>
						</div>
					)
				case 'profiles':
					return (
						<div className={`step`}>
							<h1
								className="step-title clickable"
								onClick={() => {
									setCollapsed(!collapsed)
								}}
							>
								{step.name}
								{typeof step.collapsed === 'number' && (
									<div className={`step-collapse-icon ${collapsed ? 'collapsed' : ''}`} style={{ fontSize: '28px' }}>
										<FontAwesomeIcon icon={faAngleDoubleDown} />
									</div>
								)}
							</h1>
							<div className={`step-content-container ${collapsed ? 'collapsed' : ''}`}>
								<ProfileGraphic
									profiles={[...(step.extra || []), ...((data && data.products && data.products[step.slug]) || [])]}
									stepSelect={(profile, value) => stepSelect(profile, value, stepIndex)}
									selected={step.selected}
									collapsed={collapsed}
									setCollapsed={setCollapsed}
									attributeInfo={attributeInfo}
								/>
							</div>
						</div>
					)
				default:
					return (
						<div className={`step`}>
							<h1
								className="step-title clickable"
								onClick={() => {
									setCollapsed(!collapsed)
								}}
							>
								{step.name}
								{typeof step.collapsed === 'number' && (
									<div
										className={`step-collapse-icon ${collapsed ? 'collapsed' : ''}`}
										style={{ fontSize: '28px' }}
										// onClick={() => {
										// 	setCollapsed(!collapsed)
										// }}
									>
										<FontAwesomeIcon icon={faAngleDoubleDown} />
									</div>
								)}
							</h1>
							<div className={`step-content-container ${collapsed ? 'collapsed' : ''}`}>
								<div className="step-list">
									{/* {data &&
									data.products &&
									Array.isArray(data.products[step.slug]) &&
									data.products[step.slug].map((product, i) => (
										<div
											key={i}
											className={`step-option ${step.selected && step.selected.slug === product.slug ? 'selected' : ''}`}
											style={{ '--animation-delay': 100 + 50 * i + 'ms' }}
											onClick={() => {
												stepSelect(product, product.name, stepIndex)
											}}
										>
											{product.name}
										</div>
									))} */}

									<OptionGraphic
										options={[...(step.extra || []), ...((data && data.products && data.products[step.slug]) || [])]}
										stepSelect={(option, value) => stepSelect(option, value, stepIndex)}
										selected={step.selected}
										collapsed={collapsed}
										setCollapsed={setCollapsed}
									/>
								</div>
							</div>
						</div>
					)
			}
		case 'calculate':
			return null
		// return (
		// 	<div className={`step`}>
		// 		<h1
		// 			className="step-title clickable"
		// 			onClick={() => {
		// 				setCollapsed(!collapsed)
		// 			}}
		// 		>
		// 			{step.name}
		// 			{typeof step.collapsed === 'number' && (
		// 				<div
		// 					className={`step-collapse-icon ${collapsed ? 'collapsed' : ''}`}
		// 					style={{ fontSize: '28px' }}
		// 					// onClick={() => {
		// 					// 	setCollapsed(!collapsed)
		// 					// }}
		// 				>
		// 					<FontAwesomeIcon icon={faAngleDoubleDown} />
		// 				</div>
		// 			)}
		// 		</h1>
		// 		<div>
		// 			{step.selected ? (
		// 				<div>
		// 					{step.quantity}x {step.selected && step.selected.name}
		// 				</div>
		// 			) : (
		// 				<div>Impossibile calcolarlo</div>
		// 			)}

		// 			{/* <input
		// 			className="step-length-input"
		// 			value={(step.selected && step.selected.value) || ''}
		// 			onInput={(e) => {
		// 				//^[+-]?[0-9]*[\.,]?[0-9]*$/gimu

		// 				if (/^[+-]?[0-9]*[.,]?[0-9]*$/gimu.test(e.target.value)) {
		// 					const newSteps = [...steps]
		// 					if (!newSteps[stepIndex].selected) {
		// 						newSteps[stepIndex].selected = {}
		// 					}
		// 					if (newSteps[stepIndex]) newSteps[stepIndex].selected.value = e.target.value.replace(',', '.')
		// 					newSteps[stepIndex].selected.name = e.target.value.replace(',', '.') + ' cm'

		// 					setLength(newSteps[stepIndex].selected.value)
		// 					setSteps(newSteps)
		// 				}
		// 			}}
		// 		/>
		// 		cm */}
		// 		</div>
		// 	</div>
		// )
		case 'input':
			switch (step.slug) {
				case 'spessore':
				default:
					return (
						<div className={`step`}>
							<h1 className="step-title">
								{step.name}
								{typeof step.collapsed === 'number' && (
									<div
										className={`step-collapse-icon ${collapsed ? 'collapsed' : ''}`}
										style={{ fontSize: '28px' }}
										onClick={() => {
											setCollapsed(!collapsed)
										}}
									>
										<FontAwesomeIcon icon={faAngleDoubleDown} />
									</div>
								)}
							</h1>
							<div>
								<input
									className="step-length-input"
									value={(step.selected && step.selected.value) || ''}
									onInput={(e) => {
										const newSteps = [...steps]
										if (!newSteps[stepIndex].selected) {
											newSteps[stepIndex].selected = {}
										}
										newSteps[stepIndex].selected.value = e.target.value
										newSteps[stepIndex].selected.name = e.target.value
										setSteps(newSteps)
									}}
								/>
								cm
							</div>
						</div>
					)
			}
		case 'list':
			let list = []
			const selectedStep = steps[step.listStep] && steps[step.listStep].selected
			if (selectedStep) {
				let slugsList = selectedStep[step.localSlug] ? selectedStep[step.localSlug] : selectedStep[step.slug]
				// fallback to select items
				if (!slugsList) {
					slugsList = [...(step.extra || []), ...((data && data.products && data.products[step.slug]) || [])]
				} else {
					slugsList = [...(step.extra || []), ...(slugsList || [])]
				}

				const productsList = data && data.products && data.products[step.slug]
				list =
					Array.isArray(slugsList) &&
					slugsList.map((slug) => {
						if (typeof slug === 'string') {
							if (Array.isArray(productsList)) {
								const returnValue = productsList.find((product) => product.slug === slug)
								if (returnValue) return returnValue
								return {}
							}
							return {}
						} else if (typeof slug === 'object') {
							return slug
						}
						return {}
					})
			}

			return (
				<div className={`step`}>
					<h1 className="step-title">{step.name}</h1>
					<div className={`step-content-container`}>
						<div
							className={`step-option-header ${collapsed ? 'collapsed' : ''}`}
							onClick={() => {
								setCollapsed(!collapsed)
							}}
						>
							{typeof step.collapsed === 'number' && (
								<div className={`step-collapse-icon ${collapsed ? 'collapsed' : ''}`} style={{ fontSize: '28px' }}>
									<FontAwesomeIcon icon={faAngleDoubleDown} />
								</div>
							)}
							<div className={`step-option-selected`}>
								{Array.isArray(list) && list.length > 0 ? (
									<>
										<h2 className={`step-selected-title`}>{step.selected && step.selected.name}</h2>
										<h3 className={`step-selected-subtitle`}>{step.selected && step.selected.description}</h3>
									</>
								) : (
									<h2 className={`step-selected-title`}>Selezionare prima "{steps[step.listStep].name}"</h2>
								)}
							</div>
						</div>
						<OptionGraphic
							options={list}
							stepSelect={(option, value) => stepSelect(option, value, stepIndex)}
							selected={step.selected}
							collapsed={collapsed}
							setCollapsed={setCollapsed}
						/>
					</div>
				</div>
			)
		default:
			return null
	}
}
