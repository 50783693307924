import retrieveSavedAttributes from '../retrieveSavedAttribute'

export default function exportConfiguratorProductAsText(info, quantity) {
	let savedAttribute = retrieveSavedAttributes()

	const {
		id,
		type,
		strip,
		color,
		profile,
		finishing,
		cover,
		option,
		powerSupply,
		resin,
		cable,
		cable_1,
		cable_2,
		cableFinishing,
		cablePosition,
		lengths,
		cutAngle,
		cut,
		notes,
		powerInfo,
	} = info || {}

	if (cutAngle) {
	}

	if (!info) return null

	let stripName = ''
	let profileName = ''
	switch (savedAttribute) {
		case 'led_pro':
			if (strip && strip.selected) {
				stripName = typeof strip.selected.name_ledpro === 'string' ? strip.selected.name_ledpro : strip.selected.name
			}
			if (profile && profile.selected) {
				profileName = typeof profile.selected.name_ledpro === 'string' ? profile.selected.name_ledpro : profile.selected.name
			}
			break
		case 'aeoss':
			if (strip && strip.selected) {
				stripName = strip.selected.name
			}
			if (profile && profile.selected) {
				profileName = profile.selected.name
			}
			break
		default:
			if (strip && strip.selected) {
				stripName = strip.selected.name
			}
			if (profile && profile.selected) {
				profileName = profile.selected.name
			}
	}

	let text = ''

	if (id) text += `ID: #${id}\r\n`
	if (quantity) text += `${quantity}x \r\n`

	switch (type) {
		case 'customStrip':
			text += `Striscia LED: ${stripName} - ${strip.selected.power_m}W/m - ${strip.selected.volt}V\r\n`
			text += `Temperatura colore Striscia LED: ${color && color.selected && color.selected.name}\r\n`
			text += `Tipologia di cavo iniziale: ${cut ? '2x ' : ''}${cable_1 && cable_1.selected && cable_1.selected.name}\r\n`
			text += `Tipologia di cavo finale: ${cut ? '2x ' : ''}${cable_2 && cable_2.selected && cable_2.selected.name}\r\n`
			if (Array.isArray(lengths)) {
				lengths.forEach((length, i) => {
					text += `Lunghezza${lengths.length > 1 ? ` #${i + 1}` : ''}: ${
						length.value.toFixed(1).charAt(length.value.toFixed(1).length - 1) === '0' ? length.value.toFixed(0) : length.value.toFixed(1)
					} mm\r\n`
				})
			}

			if (notes) {
				text += `\r\n${notes.replace('\n', '\r\n')}`
			}
			break
		case 'customProfile':
			text += `Profilo: ${profileName}\r\n`
			text += `Finitura Profilo: ${finishing && finishing.selected && finishing.selected.name}\r\n`
			if (cover) text += `Copertura: ${cover.selected && cover.selected.name}\r\n`
			if (option) text += `Opzione: ${option.quantity ? `${option.quantity}x ` : '1x '}${option.selected && option.selected.name}\r\n`
			if (powerSupply)
				text += `Opzione: ${powerSupply.quantity ? `${powerSupply.quantity}x ` : '1x '}${powerSupply.selected && powerSupply.selected.name}\r\n`
			if (resin) text += `Resinatura: ${resin.selected && resin.selected.name}\r\n`
			if (cable && cable.selected) text += `Tipologia di cavo: ${cut ? '2x ' : ''}${cable && cable.selected && cable.selected.name}\r\n`
			if (cableFinishing && cableFinishing.selected) text += `Colore cavo: ${cableFinishing.selected && cableFinishing.selected.name}`
			if (cablePosition && cablePosition.selected) text += `Colore cavo: ${cablePosition.selected && cablePosition.selected.name}`

			if (Array.isArray(lengths)) {
				lengths.forEach((length, i) => {
					text += `Lunghezza${lengths.length > 1 ? ` #${i + 1}` : ''}: ${
						length.value.toFixed(1).charAt(length.value.toFixed(1).length - 1) === '0' ? length.value.toFixed(0) : length.value.toFixed(1)
					} mm\r\n`
				})
			}

			text += `Accessori inclusi`

			if (notes) {
				text += `\r\n${notes.replace('\n', '\r\n')}`
			}
			break
		case 'customLamp':
			text += `Striscia LED: ${stripName} - ${strip.selected.power_m}W/m - ${strip.selected.volt}V\r\n`
			text += `Temperatura colore Striscia LED: ${color && color.selected && color.selected.name}\r\n`
			text += `Profilo: ${profileName}\r\n`
			text += `Finitura Profilo: ${finishing && finishing.selected && finishing.selected.name}\r\n`
			if (cover) text += `Copertura: ${cover.selected && cover.selected.name}\r\n`
			if (option) text += `Opzione: ${option.quantity ? `${option.quantity}x ` : '1x '}${option.selected && option.selected.name}\r\n`
			if (powerSupply)
				text += `Opzione: ${powerSupply.quantity ? `${powerSupply.quantity}x ` : '1x '}${powerSupply.selected && powerSupply.selected.name}\r\n`
			if (resin) text += `Resinatura: ${resin.selected && resin.selected.name}\r\n`
			if (cable && cable.selected) text += `Tipologia di cavo: ${cut ? '2x ' : ''}${cable && cable.selected && cable.selected.name}\r\n`
			if (cableFinishing && cableFinishing.selected) text += `Colore cavo: ${cableFinishing.selected && cableFinishing.selected.name}`
			if (cablePosition && cablePosition.selected) text += `Colore cavo: ${cablePosition.selected && cablePosition.selected.name}`
			if (Array.isArray(lengths)) {
				lengths.forEach((length, i) => {
					text += `Lunghezza${lengths.length > 1 ? ` #${i + 1}` : ''}: ${
						length.value.toFixed(1).charAt(length.value.toFixed(1).length - 1) === '0' ? length.value.toFixed(0) : length.value.toFixed(1)
					} mm\r\n`
				})
			}

			if (!powerSupply || !powerSupply.selected) {
				text += `Potenza richiesta: ${powerInfo.watt}W\r\n`
			}

			if (notes) {
				text += `\r\n${notes.replace('\n', '\r\n')}`
			}
			break
		default:
	}

	return text.replace(/\r\n+$/, '')
}
