export default function parseCustomProduct(exportData) {
	const { steps, lengths, installationMethod, cutAngle, advanced, powerInfo, notes, mode, version } = exportData

	let strip = null
	let color = null
	let profile = null
	let finishing = null
	let cover = null
	let option = null
	let powerSupply = null
	let resins = null
	let cable = null
	let cable_1 = null
	let cable_2 = null
	let cableFinishing = null
	let cablePosition = null

	steps.forEach((step) => {
		switch (step.slug) {
			case 'led_strips':
				strip = step
				break

			case 'colors':
				color = step
				break

			case 'profiles':
				profile = step
				break

			case 'finishings':
				finishing = step
				break

			case 'covers':
				if (step.active) {
					cover = step
				}
				break

			case 'options':
				if (step.active) {
					option = step
				}
				break

			case 'power_supplies':
				if (step.active) {
					powerSupply = step
				}
				break

			case 'resins':
				if (step.active) {
					resins = step
				}
				break

			case 'cables':
			case 'cable_lengths':
				if (cable_1) {
					cable_2 = step
					cable = null
				} else {
					cable = step
					cable_1 = cable
				}

				break

			case 'cable_colors':
				if (step.active) {
					cableFinishing = step
				}
				break

			case 'cable_positions':
				if (step.active) {
					cablePosition = step
				}
				break
			default:
		}
	})

	let productInfo = {
		// type: 'customLamp',
		mode: mode,
		strip: strip,
		color: color,
		profile: profile,
		finishing: finishing,
		cover: cover,
		option: option,
		powerSupply: powerSupply,
		resin: resins,
		cable: cable,
		cable_1: cable_1,
		cable_2: cable_2,
		cableFinishing: cableFinishing,
		cablePosition: cablePosition,
		lengths: lengths,
		cutAngle: cutAngle,
		cut: advanced,
		notes: notes,
		powerInfo: powerInfo,
		installationMethod: installationMethod,
		version: version ? version : 1,
	}

	return productInfo
}
