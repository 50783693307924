import { useEffect, useState, useMemo } from 'react'
import { toast } from 'react-toastify'

import Pagination from '../graphic/Pagination'
import Filters from '../graphic/Filters'
import UserForm from './UserForm'
import WebsiteUserForm from './WebsiteUser'

import useModalStore from '../../lib/stores/modal'
import useFilters from '../../lib/useFilters'
import { getUsers, deleteUser, addUserGroup } from '../../lib/adminFunctions'
import checkTokenError from '../../lib/checkTokenError'

import '../../styles/components/List.scss'

export default function UserList() {
	const addModal = useModalStore((state) => state.addModal)
	const [users, setUsers] = useState()

	// Pagination Info
	const [currentPage, setCurrentPage] = useState(0)
	const [pageSize, setPageSize] = useState(50)

	// FilterInfo
	const [attributes, setAttributes] = useState([
		{ key: 'email', enabled: false, visible: false },
		{ key: 'firstname', enabled: false, visible: false },
		{ key: 'lastname', enabled: false, visible: false },
		{ key: 'company', enabled: false, visible: false },
	])
	const [searchString, setSearchString] = useState('')

	useEffect(() => {
		getUsers()
			.then((res) => {
				if (res) setUsers(res)
			})
			.catch((e) => {
				console.error(e)

				let message = checkTokenError(e)
				if (!message) message = 'Errore caricando utenti'

				toast.error({
					render: message,
					type: 'error',
					autoClose: 5000,
					isLoading: false,
					closeOnClick: true,
					pauseOnHover: true,
				})
			})
	}, [])

	const filteredData = useFilters({ data: users, attributes: attributes, searchString: searchString })

	const pageData = useMemo(() => {
		if (!Array.isArray(filteredData)) {
			return []
		}

		const firstPageIndex = (currentPage - 1) * pageSize
		const lastPageIndex = firstPageIndex - -pageSize
		return filteredData.slice(firstPageIndex, lastPageIndex)
	}, [currentPage, pageSize, filteredData])

	return (
		<div className="list-list">
			<div className="list-actions">
				<div
					className="action-button"
					onClick={() => {
						let name = window.prompt('Inserisci Nome per il gruppo utente:', '')

						if (name) {
							addUserGroup(name)
						}
					}}
				>
					Nuovo gruppo utenti
				</div>
				<div
					className="action-button"
					onClick={() => {
						getUsers().then((res) => {
							if (res) setUsers(res)
						})
					}}
				>
					Sincronizza
				</div>
				<div
					className="action-button"
					onClick={() => {
						addModal({
							modal: <UserForm mode="register" />,
							id: 'RegisterUserModal',
							background: true,
							replaceModal: true,
							customModal: true,
							disposableModal: true,
						})
					}}
				>
					Nuovo
				</div>
				<div
					className="action-button"
					onClick={() => {
						addModal({
							modal: <WebsiteUserForm mode="register" />,
							id: 'RegisterUserAeossModal',
							background: true,
							replaceModal: true,
							customModal: true,
							disposableModal: true,
						})
					}}
				>
					Nuovo Utente Aeoss
				</div>
			</div>

			<Filters data={users} attributes={attributes} setAttributes={setAttributes} searchString={searchString} setSearchString={setSearchString} />
			<Pagination
				currentPage={currentPage}
				totalCount={(filteredData && filteredData.length) || 1}
				pageSize={pageSize}
				setPageSize={setPageSize}
				onPageChange={(page) => setCurrentPage(page)}
			/>
			<div className="list-row row-header">
				<div className="list-column item-medium">Email</div>
				<div className="list-column item-medium">{`Nome Cognome`}</div>
				<div className="list-column item-small">Modifica</div>
				<div className="list-column item-small">Elimina</div>
			</div>
			{Array.isArray(pageData) &&
				pageData.map((user, i) => {
					return (
						<div key={i} className="list-row">
							<div className="list-column item-medium">{user.email}</div>
							<div className="list-column item-medium">{`${user.firstname} ${user.lastname}`}</div>
							<div
								className="list-column item-small item-clickable"
								onClick={() => {
									if (user.userOrigin === 'aeoss-website') {
										addModal({
											modal: <WebsiteUserForm userData={user} mode="edit" />,
											id: 'EditUserAeossModal',
											background: true,
											replaceModal: true,
											customModal: true,
											disposableModal: true,
										})
									} else {
										addModal({
											modal: <UserForm userData={user} mode="edit" />,
											id: 'EditUserModal',
											background: true,
											replaceModal: true,
											customModal: true,
											disposableModal: true,
										})
									}
								}}
							>
								Modifica
							</div>
							<div
								className="list-column item-small item-clickable"
								onClick={() => {
									const r = window.confirm('Sei sicuro di voler elliminare questo utente?')

									if (r) {
										deleteUser(user._id).then(() => {
											getUsers().then((res) => {
												if (res) setUsers(res)
											})
										})
									}
								}}
							>
								Elimina
							</div>
						</div>
					)
				})}
		</div>
	)
}
