import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import { v4 } from 'uuid'

import retrieveSavedAttributes from '../../../retrieveSavedAttribute'
import useConfigurationStore from '../../configurations'
import useUserStore from '../../user'

pdfMake.vfs = pdfFonts.pdfMake.vfs

let localUrl = process.env.REACT_APP_LOCAL_URL

export default async function exportMaterialList(get, set, unique = true) {
	const document = get().document

	const configurations = useConfigurationStore.getState().configurations
	const getUserGroup = useUserStore.getState().getUserGroup
	const getUserGroupId = useUserStore.getState().getUserGroupId
	const user = useUserStore.getState().user

	const exportRemoveID = configurations.exportRemoveID
	const exportSKU = configurations.exportSKU

	let savedAttribute = retrieveSavedAttributes()

	let tableRows = []
	let pdfJson = {
		info: {
			title: 'Preventivo',
			author: 'etechSRL',
			subject: 'Preventivo',
			keywords: ['preventivo', 'quote'],
		},
		content: [],
		// pageBreakBefore: function (currentNode, nodeContainer) {
		// 	console.log(currentNode.headlineLevel === 1 && nodeContainer.getFollowingNodesOnPage().length === 0, currentNode, nodeContainer)
		// 	if (currentNode.headlineLevel === 1 && nodeContainer.getFollowingNodesOnPage().length === 0) {
		// 		return true
		// 	}
		// 	return false
		// },
		styles: {
			referer: { fontSize: 12, margin: [1, 3, 4, 4] },
			shipping: { bold: true, margin: [1, 3, 4, 4], fontSize: 14 },
			description: { margin: [1, 3, 4, 4], fontSize: 11 },
			zoneTitle: { bold: true, margin: [-4, 2, 0, 4], fontSize: 16 },
			tableHeader: { bold: true, margin: [2, 4, 4, 4], fontSize: 12 },
			tableFooter: { margin: [3, 2, 3, 3], fontSize: 10, bold: true },
			tableCell: { margin: [4, 3, 4, 4], fontSize: 10 },
			tableCellOdd: {},
			tableCellEven: {},
			itemQty: { margin: [4, 2, 2, 2] },
			itemName: {},
			itemLength: {},
			itemInfo: {},
			itemPriceCad: {},
			itemPriceTot: {},
			tableTotalPrice: {},
		},
		pageMargins: [30, 40, 30, 40],
		// images: {
		// 	testImage: 'http://localhost:3030/images/taglio-profilo.png',
		// },
	}

	let date
	if (document.quoteDate) {
		date = new Date(document.quoteDate)
	} else if (document.updatedAt) {
		date = new Date(document.updatedAt)
	} else {
		date = new Date(Date.now())
	}

	pdfJson.content.push({ text: `${date.getDate()} / ${date.getMonth() + 1} / ${date.getFullYear()}`, style: 'date', alignment: 'right' })

	if (document.referer) {
		pdfJson.content.push({ text: `riferimento: ${document.referer}`, style: 'referer' })
	}

	let userGroup
	let userGroupDownload

	try {
		userGroup = await getUserGroup()
	} catch (e) {
		console.error(e)
	}
	try {
		userGroupDownload = await getUserGroupId(document && document.userGroupId)
	} catch (e) {
		console.error(e)
	}

	if (document.identifier && !exportRemoveID) {
		let userSlug = user.slug
		let id = document.identifier
		let userGroupId = userGroupDownload && userGroupDownload.id

		if (userGroupDownload instanceof Error) {
			userGroupId = userGroup && userGroup.id
		}

		// console.log(userGroupId, typeof userGroupId)

		id = id.replace(/^0+/, '')

		pdfJson.content.push({
			text: `ID: ${userSlug}${userGroupId ? '-' + userGroupId : ''}-${id}${document.versionNumber ? '_' + document.versionNumber : ''}`,
			style: 'referer',
		})
	}
	// if (document.id) {
	// 	pdfJson.content.push({ text: `id preventivo: ${document.id}`, style: 'referer' })
	// }

	// pdfJson.content.push({
	// 	toc: {
	// 		id: 'mainIndex',
	// 		title: { text: 'Indice', style: 'header' },
	// 		style: 'index',
	// 	},
	// })

	const productList = {}

	let title = 'Lista materiale'
	// switch (key) {
	// 	case 'noCategory':
	// 		title = 'Prodotti senza categoria'
	// 		break
	// 	case 'custom':
	// 		title = 'Prodotti personalizzati'
	// 		break
	// 	case 'led_strip':
	// 		title = 'Strisce LED'
	// 		break
	// 	case 'profiles':
	// 		title = 'Profili'
	// 		break
	// 	case 'power_supplies':
	// 		title = 'Alimentatori'
	// 		break
	// 	case 'profile_accessories':
	// 		title = 'Accessori profili'
	// 		break
	// 	case 'accessories':
	// 		title = 'Accessori'
	// 		break
	// 	case 'downlights':
	// 		title = ''
	// 		break
	// 	case 'controllers':
	// 		title = 'Controllers'
	// 		break
	// 	case 'frames':
	// 		break
	// 	case 'outdoor_lamps':
	// 		break
	// 	case 'light_bulbs':
	// 		break
	// 	case 'track_lamps':
	// 		break
	// 	case 'lamps':
	// 		title = 'Lampade'
	// 		break
	// 	case 'ceiling_lights':
	// 		break
	// }

	let tableHeaders = [
		{ text: 'Qtà', style: 'tableHeader', fontSize: 11, border: [true], margin: [2, 4, 4, 4] },
		{ text: exportSKU ? 'COD' : 'COD', style: 'tableHeader', border: [true], margin: [2, 4, 4, 4] },
		{ text: 'Prodotto', style: 'tableHeader', border: [true], margin: [2, 4, 4, 4] },
		{ text: 'Lung.', style: 'tableHeader', border: [true], margin: [2, 4, 4, 4] },
		{ text: 'Info', style: 'tableHeader', border: [true], margin: [2, 4, 4, 4] },
	]

	let widths = [60, exportSKU ? 80 : 60, '*', 60, 100]

	let hasInfo = false
	let hasLength = false

	if (Array.isArray(document.elements)) {
		document.elements.forEach((element) => {
			switch (element.type) {
				case 'shipping':
					if (element.shipping) {
						pdfJson.content.push({ text: `Spedizione:`, style: 'shipping' })

						pdfJson.content.push({
							stack: [`${element.shipping.name} - ${element.shipping.price} €`, element.shipping.notesPlainText],
							style: 'description',
						})
					}
					break
				case 'zone':
					// Check if info is set in at least 1 item, if there is none, remove info from headers

					// // insert fake header to make colums
					// tableRows.push(tableHeaders.map(() => ({ text: '', border: [false, false, false, element.title && element.title !== '' ? false : true] })))

					// // add zone title as header so it sticks to table
					// if (element.title && element.title !== '') {
					// 	// pdfJson.content.push({ text: `${element.title}`, style: 'zoneTitle' })
					// 	tableRows.push([
					// 		{
					// 			text: `${element.title}`,
					// 			style: 'zoneTitle',
					// 			colSpan: tableHeaders.length,
					// 			border: [false, false, false, true],
					// 			// tocItem: 'mainIndex',
					// 		},
					// 	])
					// }

					if (Array.isArray(element.items)) {
						element.items.forEach((item, i) => {
							// if item is empty (no quantity or no name and info) ignore
							if (!item.name && !item.infoPlainText && item.type !== 'customProduct') return

							let rowComponents = []

							let colSpan = 4

							// console.log(item.type)
							switch (item.type) {
								case 'customProduct':
									rowComponents.push({
										text: item.quantity,
										style: [`tableCell`, `itemQty`],
									})
									// no COD so blank

									colSpan = 4
									// if (length) colSpan++
									// if (infoPresent) colSpan++

									let text = []

									let info = item && item.productInfo

									const {
										type,
										strip,
										color,
										profile,
										finishing,
										cover,
										option,
										powerSupply,
										resin,
										cable,
										cable_1,
										cable_2,
										lengths,
										cutAngle,
										cut,
										notes,
									} = info || {}

									if (cutAngle) {
									}

									let stripName = ''
									// let stripDescription = ''
									let profileName = ''
									// let profileDescription = ''
									switch (savedAttribute) {
										case 'led_pro':
											if (strip && strip.selected) {
												stripName = typeof strip.selected.name_ledpro === 'string' ? strip.selected.name_ledpro : strip.selected.name
												// stripDescription =
												// 	typeof strip.selected.description_ledpro === 'string'
												// 		? strip.selected.description_ledpro
												// 		: strip.selected.description
											}
											if (profile && profile.selected) {
												profileName =
													typeof profile.selected.name_ledpro === 'string' ? profile.selected.name_ledpro : profile.selected.name
												// profileDescription =
												// 	typeof profile.selected.description_ledpro === 'string'
												// 		? profile.selected.description_ledpro
												// 		: profile.selected.description
											}
											break
										case 'aeoss':
											if (strip && strip.selected) {
												stripName = strip.selected.name
												// stripDescription = strip.selected.description
											}
											if (profile && profile.selected) {
												profileName = profile.selected.name
												// profileDescription = profile.selected.description
											}
											break
										default:
											if (strip && strip.selected) {
												stripName = strip.selected.name
												// stripDescription = strip.selected.description
											}
											if (profile && profile.selected) {
												profileName = profile.selected.name
												// profileDescription = profile.selected.description
											}
									}

									switch (type) {
										case 'customStrip':
											if (info.id)
												text.push({
													text: [{ text: `ID: `, bold: true }, info.id],
												})
											text.push({
												text: [{ text: `Striscia LED a misura:`, bold: true }],
											})
											text.push({
												text: [
													{ text: `Striscia LED: `, bold: true },
													`${strip && strip.selected && stripName} - ${strip.selected.power_m}W/m - ${strip.selected.volt}V`,
												],
											})
											text.push({
												text: [
													{ text: `Temperatura colore Striscia LED: `, bold: true },
													color && color.selected && color.selected.name,
												],
											})
											text.push({
												text: [
													{ text: `Tipologia di cavo iniziale: `, bold: true },
													cable_1 && cable_1.selected && cable_1.selected.name,
												],
											})
											text.push({
												text: [
													{ text: `Tipologia di cavo finale: `, bold: true },
													cable_2 && cable_2.selected && cable_2.selected.name,
												],
											})

											if (Array.isArray(lengths)) {
												lengths.forEach((length, i) => {
													text.push({
														text: [
															{ text: `Lunghezza${lengths.length > 1 ? ` #${i + 1}` : ''}: `, bold: true },
															`${length.value * (length.multiplier || 1)} ${length.unit || 'cm'}`,
														],
													})
												})
											}
											if (notes) {
												text.push({
													text: [{ text: `Note: `, bold: true }, notes],
												})
											}
											break
										case 'customProfile':
											if (info.id)
												text.push({
													text: [{ text: `ID: `, bold: true }, info.id],
												})
											text.push({
												text: [{ text: `Taglio profilo a misura:`, bold: true }],
											})
											text.push({
												text: [{ text: `Profilo: `, bold: true }, profile && profile.selected && profileName],
											})
											text.push({
												text: [{ text: `Finitura profilo: `, bold: true }, finishing && finishing.selected && finishing.selected.name],
											})
											if (cover) {
												text.push({
													text: [{ text: `Copertura: `, bold: true }, cover.selected && cover.selected.name],
												})
											}
											if (option) {
												text.push({
													text: [
														{ text: `Opzione: ${option.quantity ? `${option.quantity}x ` : '1x '}`, bold: true },
														option.selected && option.selected.name,
													],
												})
											}
											if (powerSupply) {
												text.push({
													text: [
														{ text: `Alimentatore/i: ${option.quantity ? `${option.quantity}x ` : '1x '}`, bold: true },
														powerSupply.selected && powerSupply.selected.name,
													],
												})
											}
											if (resin) {
												text.push({
													text: [{ text: `Trattamenti: `, bold: true }, resin.selected && resin.selected.name],
												})
											}
											text.push({
												text: [
													{ text: `Tipologia di cavo: ${cut ? '2x ' : ''}`, bold: true },
													cable && cable.selected && cable.selected.name,
												],
											})
											if (Array.isArray(lengths)) {
												lengths.forEach((length, i) => {
													text.push({
														text: [
															{ text: `Lunghezza${lengths.length > 1 ? ` #${i + 1}` : ''}: `, bold: true },
															`${length.value * (length.multiplier || 1)} ${length.unit || 'cm'}`,
														],
													})
												})
											}
											if (notes) {
												text.push({
													text: [{ text: `Note: `, bold: true }, notes],
												})
											}
											break
										case 'customLamp':
											if (info.id)
												text.push({
													text: [{ text: `ID: `, bold: true }, info.id],
												})
											text.push({
												text: [{ text: `Lampada a misura:`, bold: true }],
											})
											text.push({
												text: [
													{ text: `Striscia LED: `, bold: true },
													`${strip && strip.selected && stripName} - ${strip.selected.power_m}W/m - ${strip.selected.volt}V`,
												],
											})
											text.push({
												text: [
													{ text: `Temperatura colore Striscia LED: `, bold: true },
													color && color.selected && color.selected.name,
												],
											})
											text.push({
												text: [{ text: `Profilo: `, bold: true }, profile && profile.selected && profileName],
											})
											text.push({
												text: [{ text: `Finitura profilo: `, bold: true }, finishing && finishing.selected && finishing.selected.name],
											})
											if (cover) {
												text.push({
													text: [{ text: `Copertura: `, bold: true }, cover.selected && cover.selected.name],
												})
											}
											if (option) {
												text.push({
													text: [
														{ text: `Opzione: ${option.quantity ? `${option.quantity}x ` : '1x '}`, bold: true },
														option.selected && option.selected.name,
													],
												})
											}
											if (powerSupply) {
												text.push({
													text: [
														{ text: `Alimentatore/i: ${option.quantity ? `${option.quantity}x ` : '1x '}`, bold: true },
														powerSupply.selected && powerSupply.selected.name,
													],
												})
											}
											if (resin) {
												text.push({
													text: [{ text: `Trattamenti: `, bold: true }, resin.selected && resin.selected.name],
												})
											}
											text.push({
												text: [
													{ text: `Tipologia di cavo: ${cut ? '2x ' : ''}`, bold: true },
													cable && cable.selected && cable.selected.name,
												],
											})
											if (Array.isArray(lengths)) {
												lengths.forEach((length, i) => {
													text.push({
														text: [
															{ text: `Lunghezza${lengths.length > 1 ? ` #${i + 1}` : ''}: `, bold: true },
															`${length.value * (length.multiplier || 1)} ${length.unit || 'cm'}`,
														],
													})
												})
											}

											if (notes) {
												text.push({
													text: [{ text: `Note: `, bold: true }, notes],
												})
											}
											break
										default:
											break
									}

									// console.log(text)

									rowComponents.push({
										// columns: [
										// 	{ image: 'testImage', width: 50 },
										// 	{ stack: text, margin: [15, 0, 0, 0] },
										// ],
										stack: text,
										colSpan: colSpan,
										style: [`tableCell`, `itemName`],
									})

									if (!productList.custom) {
										productList.custom = {}
									}

									if (info.id && productList.custom[info.id]) {
										productList.custom[info.id].quantity = productList.custom[info.id].quantity + parseInt(item.quantity)
									} else {
										let id = v4()
										productList.custom[info.id ? info.id : id] = {
											quantity: parseInt(item.quantity),
											customData: rowComponents,
										}
									}

									// if (length) {
									// 	rowComponents.push({
									// 		text: '',
									// 		style: [`tableCell`, `itemLength`],
									// 	})
									// }
									// if (infoPresent) {
									// 	rowComponents.push({
									// 		text: '',
									// 		style: [`tableCell`, `itemInfo`],
									// 	})
									// }
									break
								case 'descriptiveItem':
									if (item.infoPlainText && item.infoPlainText.trim()) {
										hasInfo = true
									}
									if (item.length && item.length !== 1 && item.length !== '1') {
										hasLength = true
									}
									if (item.product && item.product.category) {
										if (!productList[item.product.category]) {
											productList[item.product.category] = {}
										}
										if (
											!productList[item.product.category][`${item.namePlainText}___${unique ? item.infoPlainText : ''}___${item.length}`]
										) {
											productList[item.product.category][`${item.namePlainText}___${unique ? item.infoPlainText : ''}___${item.length}`] =
												{
													quantity: parseInt(item.quantity),
													cod: exportSKU ? item.product.sku : item.product.cod,
													name: item.namePlainText,
													info: item.infoPlainText,
													length: item.length,
												}
										} else {
											productList[item.product.category][
												`${item.namePlainText}___${unique ? item.infoPlainText : ''}___${item.length}`
											].quantity =
												productList[item.product.category][
													`${item.namePlainText}___${unique ? item.infoPlainText : ''}___${item.length}`
												].quantity + parseInt(item.quantity)
										}
									} else {
										if (!productList.noCategory) {
											productList.noCategory = {}
										}
										if (!productList.noCategory[`${item.namePlainText}___${unique ? item.infoPlainText : ''}___${item.length}`]) {
											productList.noCategory[`${item.namePlainText}___${unique ? item.infoPlainText : ''}___${item.length}`] = {
												quantity: parseInt(item.quantity),
												cod: exportSKU ? item.product?.sku : item.product?.cod,
												name: item.namePlainText,
												info: item.infoPlainText,
												length: item.length,
											}
										} else {
											productList.noCategory[`${item.namePlainText}___${unique ? item.infoPlainText : ''}___${item.length}`].quantity =
												productList.noCategory[`${item.namePlainText}___${unique ? item.infoPlainText : ''}___${item.length}`]
													.quantity + parseInt(item.quantity)
										}
									}
									break
								default:
									if (item.infoPlainText && item.infoPlainText.trim()) {
										hasInfo = true
									}
									if (item.length && item.length !== 1 && item.length !== '1') {
										hasLength = true
									}
									if (item.product && item.product.category) {
										if (!productList[item.product.category]) {
											productList[item.product.category] = {}
										}
										if (
											!productList[item.product.category][
												unique
													? `${item.name}___${item.infoPlainText}___${item.length}`
													: `${item?.variant?.cod ? item.variant.cod : item?.product?.cod ? item.product.cod : item.name}__${
															item.length
													  }`
											]
										) {
											productList[item.product.category][
												unique
													? `${item.name}___${item.infoPlainText}___${item.length}`
													: `${item?.variant?.cod ? item.variant.cod : item?.product?.cod ? item.product.cod : item.name}__${
															item.length
													  }`
											] = {
												quantity: parseInt(item.quantity),
												cod: exportSKU ? item.product.sku : item.product.cod,
												name: item.name,
												info: item.infoPlainText,
												length: item.length,
											}
										} else {
											productList[item.product.category][
												unique
													? `${item.name}___${item.infoPlainText}___${item.length}`
													: `${item?.variant?.cod ? item.variant.cod : item?.product?.cod ? item.product.cod : item.name}__${
															item.length
													  }`
											].quantity =
												productList[item.product.category][
													unique
														? `${item.name}___${item.infoPlainText}___${item.length}`
														: `${item?.variant?.cod ? item.variant.cod : item?.product?.cod ? item.product.cod : item.name}__${
																item.length
														  }`
												].quantity + parseInt(item.quantity)
										}
									} else {
										if (!productList.noCategory) {
											productList.noCategory = {}
										}
										if (
											!productList.noCategory[
												unique
													? `${item.name}___${item.infoPlainText}___${item.length}`
													: `${item?.variant?.cod ? item.variant.cod : item?.product?.cod ? item.product.cod : item.name}__${
															item.length
													  }`
											]
										) {
											productList.noCategory[
												unique
													? `${item.name}___${item.infoPlainText}___${item.length}`
													: `${item?.variant?.cod ? item.variant.cod : item?.product?.cod ? item.product.cod : item.name}__${
															item.length
													  }`
											] = {
												quantity: parseInt(item.quantity),
												cod: exportSKU ? item?.product?.sku : item?.product?.cod,
												name: item.name,
												info: item.infoPlainText,
												length: item.length,
											}
										} else {
											productList.noCategory[
												unique
													? `${item.name}___${item.infoPlainText}___${item.length}`
													: `${item?.variant?.cod ? item.variant.cod : item?.product?.cod ? item.product.cod : item.name}__${
															item.length
													  }`
											].quantity =
												productList.noCategory[
													unique
														? `${item.name}___${item.infoPlainText}___${item.length}`
														: `${item?.variant?.cod ? item.variant.cod : item?.product?.cod ? item.product.cod : item.name}__${
																item.length
														  }`
												].quantity + parseInt(item.quantity)
										}
									}
									rowComponents.push({
										text: item.quantity,
										style: [`tableCell`, `itemQty`],
									})
							}
						})
					}

					break
				default:
			}
		})

		if (!hasInfo || !unique) {
			tableHeaders = tableHeaders.filter((header, i) => {
				if (header.text === 'Info') {
					widths.splice(i, 1)
					return false
				}
				return true
			})
		}

		if (!hasLength) {
			tableHeaders = tableHeaders.filter((header, i) => {
				if (header.text === 'Lung.') {
					widths.splice(i, 1)
					return false
				}
				return true
			})
		}

		tableRows.push(tableHeaders.map(() => ({ text: '', border: [false, false, false, title && title !== '' ? false : true] })))

		// add zone title as header so it sticks to table
		if (title && title !== '') {
			// pdfJson.content.push({ text: `${element.title}`, style: 'zoneTitle' })
			tableRows.push([
				{
					text: `${title}`,
					style: 'zoneTitle',
					colSpan: tableHeaders.length,
					border: [false, false, false, true],
					// tocItem: 'mainIndex',
				},
			])
		}

		tableRows.push(tableHeaders)

		Object.entries(productList).forEach(([key, value]) => {
			let sortedProducts = Object.entries(value)

			if (sortedProducts.length > 1) {
				sortedProducts.sort((a, b) => a[0].localeCompare(b[0]))
			}

			sortedProducts.forEach(([keyItem, valueItem]) => {
				// console.log(key, valueItem)
				if (valueItem.customData) {
					let rowData = []
					rowData.push({
						text: valueItem.quantity,
						style: [`tableCell`, `itemQty`],
					})

					// 2 because name + COD
					let colSpan = 2
					if (hasInfo && unique) colSpan++
					if (hasLength) colSpan++
					rowData.push({ ...valueItem.customData[1], colSpan: colSpan })

					tableRows.push(rowData)
				} else {
					let rowData = [
						{
							text: valueItem.quantity,
							style: [`tableCell`, `itemQty`],
						},
						{
							text: valueItem.cod,
							style: [`tableCell`, `itemQty`],
						},
						{
							text: valueItem.name,
							style: [`tableCell`, `itemQty`],
						},
					]

					if (hasLength) {
						rowData.push({
							text: valueItem.length !== 1 && valueItem.length !== '1' ? valueItem.length : '',
							style: [`tableCell`, `itemQty`],
						})
					}

					if (hasInfo && unique) {
						rowData.push({
							text: valueItem.info,
							style: [`tableCell`, `itemQty`],
						})
					}

					tableRows.push(rowData)
				}
			})
		})

		// let length = Array.isArray(element.items) && element.items.some((item) => item.showLength || (item.product && item.product.category === 'profiles'))
		// if (!length) {
		// 	tableHeaders = tableHeaders.filter((header, i) => {
		// 		if (header.text === 'Lung.') {
		// 			widths.splice(i, 1)
		// 			return false
		// 		}
		// 		return true
		// 	})
		// }
	}

	pdfJson.content.push({
		layout: {
			hLineWidth(i, node) {
				// if (i === 0 || i === node.table.body.length) {
				// 	return 0
				// }
				return i === node.table.headerRows ? 2 : 1
			},
			vLineWidth(i) {
				return 1
			},
			hLineColor: function (i, node) {
				// return '#E7EAEC'
				return i === node.table.headerRows ? '#DEE1E3' : '#E7EAEC'
			},
			vLineColor: function (i, node) {
				// return '#E7EAEC'
				return '#E7EAEC'
			},
			paddingLeft(i) {
				return 4
			},
			paddingRight(i, node) {
				return i === node.table.widths.length - 1 ? 1 : 4
			},
			fillColor: function (rowIndex, node, columnIndex) {
				// if (rowIndex === node.table.body.length - 1 && columnIndex === node.table.widths.length - 1) {
				// 	return '#D5F0FF'
				// }

				if (rowIndex === node.table.body.length - 1) {
					return '#F6F8FA'
				}

				return rowIndex >= node.table.headerRows && (rowIndex - node.table.headerRows) % 2 === 1 ? '#F8FAFC' : null
			},
		},
		table: {
			// headers are automatically repeated if the table spans over multiple pages
			// you can declare how many rows should be treated as headers
			// headerRows: title && title !== '' ? 3 : 2,
			headerRows: 2,
			widths: widths,

			body: tableRows,
			dontBreakRows: true,
			keepWithHeaderRows: 1,
		},
		margin: [0, 10, 0, 5],
	})

	pdfJson.defaultStyle = {
		font: 'Rubik',
		lineHeight: 1,
	}

	const fonts = {
		// mulish: { normal: `${localUrl}/fonts/Mulish-Regular.ttf`, bold: `${localUrl}/fonts/Mulish-Regular.ttf` },
		// sawarabiGothic: {
		// 	normal: `${localUrl}/fonts/SawarabiGothic-Regular.ttf`,
		// 	bold: `${localUrl}/fonts/SawarabiGothic-Regular.ttf`,
		// },
		// SauceHanSansJP: {
		// 	normal: `${localUrl}/fonts/SauceHanSansJP-Regular.ttf`,
		// 	bold: `${localUrl}/fonts/SauceHanSansJP-Bold.ttf`,
		// },
		Rubik: {
			normal: `${localUrl}/fonts/Rubik-Regular.ttf`,
			bold: `${localUrl}/fonts/Rubik-SemiBold.ttf`,
			// light: `${localUrl}/fonts/Rubik-Light.ttf`,
		},
	}

	const now = new Date()
	const dd = String(now.getDate()).padStart(2, '0')
	const mn = String(now.getMonth() + 1).padStart(2, '0')
	const yyyy = now.getFullYear()
	const hh = String(now.getHours()).padStart(2, '0')
	const mm = String(now.getMinutes() + 1).padStart(2, '0')

	const today = `${yyyy}-${mn}-${dd}_${hh}-${mm}`
	const fileName = 'Lista_materiale_' + today + '.pdf'

	pdfMake.createPdf(pdfJson, null, fonts).download(fileName)
}
