import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTape, faPalette, faBorderStyle, faPaintBrush, faPowerOff, faPlug, faSprayCan, faBolt } from '@fortawesome/free-solid-svg-icons'

export default function Preview({ lengths, advanced, cutAngle, installationMethod, steps, powerInfo, price, attributeInfo }) {
	function slugToIcon(slug) {
		switch (slug) {
			case 'led_strips':
				return (
					<div className="side-preview-icon">
						<div style={{ fontSize: '1.75em' }}>
							<FontAwesomeIcon icon={faTape} />
						</div>
					</div>
				)
			case 'colors':
				return (
					<div className="side-preview-icon">
						<div style={{ fontSize: '1.75em' }}>
							<FontAwesomeIcon icon={faPalette} />
						</div>
					</div>
				)
			case 'profiles':
				return (
					<div className="side-preview-icon">
						<div style={{ fontSize: '1.75em' }}>
							<FontAwesomeIcon icon={faBorderStyle} />
						</div>
					</div>
				)
			case 'finishings':
				return (
					<div className="side-preview-icon">
						<div style={{ fontSize: '1.75em' }}>
							<FontAwesomeIcon icon={faPaintBrush} />
						</div>
					</div>
				)
			case 'covers':
				return (
					<div className="side-preview-icon">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1.75em" height="1.75em" style={{ transform: 'rotateX(180deg)' }}>
							<path d="M4 9V13H20V9H22V14C22 14.5523 21.5523 15 21 15H3C2.44772 15 2 14.5523 2 14V9H4Z"></path>
						</svg>
					</div>
				)
			case 'options':
				return (
					<div className="side-preview-icon">
						<div style={{ fontSize: '1.75em' }}>
							<FontAwesomeIcon icon={faPowerOff} />
						</div>
					</div>
				)
			case 'power_supplies':
				return (
					<div className="side-preview-icon">
						<div style={{ fontSize: '1.75em' }}>
							<FontAwesomeIcon icon={faPlug} />
						</div>
					</div>
				)
			case 'resins':
				return (
					<div className="side-preview-icon">
						<div style={{ fontSize: '1.75em' }}>
							<FontAwesomeIcon icon={faSprayCan} />
						</div>
					</div>
				)
			case 'cables':
			case 'cable_lengths':
				return (
					<div className="side-preview-icon">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1.75em" height="1.75em">
							<path fill="none" d="M0 0h24v24H0z" />
							<path d="M2 11h2v2H2v-2zm4 0h12v2H6v-2zm14 0h2v2h-2v-2z" />
						</svg>
					</div>
				)
			default:
				return <div></div>
		}
	}

	return (
		<>
			<div className="side-length-preview">
				{Array.isArray(lengths) &&
					lengths.map((length, i) => (
						<div key={i} className="side-preview-option">
							<div className="side-preview-icon">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1.75em" height="1.75em">
									<path fill="none" d="M0 0h24v24H0z" />
									<path d="M6.343 14.621L3.515 17.45l3.535 3.535L20.485 7.55 16.95 4.015l-2.122 2.121 1.415 1.414-1.415 1.414-1.414-1.414-2.121 2.122 2.121 2.12L12 13.208l-2.121-2.121-2.122 2.121 1.415 1.414-1.415 1.415-1.414-1.415zM17.657 1.893l4.95 4.95a1 1 0 0 1 0 1.414l-14.85 14.85a1 1 0 0 1-1.414 0l-4.95-4.95a1 1 0 0 1 0-1.414l14.85-14.85a1 1 0 0 1 1.414 0z" />
								</svg>
							</div>
							<span>
								<span className="side-preview-name">Lunghezza:</span>
								<span className="step-preview-value">
									{length && length.value * length.multiplier}
									{length && length.unit}
								</span>
							</span>
						</div>
					))}
				{advanced && (
					<>
						<div className="side-preview-option">
							<div className="side-preview-icon">
								{/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1.75em" height="1.75em">
									<path fill="none" d="M0 0h24v24H0z" />
									<path d="M10 6c0 .732-.197 1.419-.54 2.01L12 10.585l6.728-6.728a2 2 0 0 1 2.828 0l-12.11 12.11a4 4 0 1 1-1.414-1.414L10.586 12 8.032 9.446A4 4 0 1 1 10 6zM8 6a2 2 0 1 0-4 0 2 2 0 0 0 4 0zm13.556 14.142a2 2 0 0 1-2.828 0l-5.317-5.316 1.415-1.415 6.73 6.731zM16 11h2v2h-2v-2zm4 0h2v2h-2v-2zM6 11h2v2H6v-2zm-4 0h2v2H2v-2zm4 9a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
								</svg> */}
								<svg xmlns="http://www.w3.org/2000/svg" width="1.75em" height="1.75em" viewBox="0 0 24 24">
									<path
										style={{
											fill: 'none',
											fillOpacity: 0.597734,
											stroke: '#000',
											strokeWidth: '2px',
											strokeMiterlimit: 4,
											strokeDasharray: 'none',
										}}
										d="M 2.0972741,11.460681 A 10.91462,10.91462 0 0 1 -4.158239,24.574402 10.91462,10.91462 0 0 1 -17.899504,19.854365 10.91462,10.91462 0 0 1 -14.776465,5.6646595 l 6.3938537,8.8457665 z"
										transform="rotate(-73.913)"
									/>
								</svg>
							</div>
							<span>
								<span className="side-preview-name">Angolo di taglio:</span>
								<span className="step-preview-value">{cutAngle}</span>
							</span>
						</div>
						<div className="side-preview-option">
							<div className="side-preview-icon">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1.75em" height="1.75em">
									<path fill="none" d="M0 0h24v24H0z" />
									<path d="M16.33 13.5A6.988 6.988 0 0 0 19 8h2a8.987 8.987 0 0 1-3.662 7.246l2.528 4.378a2 2 0 0 1-.732 2.732l-3.527-6.108A8.97 8.97 0 0 1 12 17a8.97 8.97 0 0 1-3.607-.752l-3.527 6.108a2 2 0 0 1-.732-2.732l5.063-8.77A4.002 4.002 0 0 1 11 4.126V2h2v2.126a4.002 4.002 0 0 1 1.803 6.728L16.33 13.5zM14.6 14.502l-1.528-2.647a4.004 4.004 0 0 1-2.142 0l-1.528 2.647c.804.321 1.68.498 2.599.498.918 0 1.795-.177 2.599-.498zM12 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4z" />
								</svg>
							</div>
							<span>
								<span className="side-preview-name">Installazione:</span>
								<span className="step-preview-value">{installationMethod && installationMethod.name}</span>
							</span>
							{installationMethod && installationMethod.info && <span className="side-preview-info">{installationMethod.info}</span>}
						</div>
						{installationMethod && installationMethod.image && (
							<div className="step-preview-image">
								<img src={`./images/${installationMethod.image}`} alt={installationMethod.imageAlt} />
							</div>
						)}
					</>
				)}
			</div>
			<ul className="step-preview-list">
				{Array.isArray(steps) &&
					steps.map((step, i) => {
						if (step.optional && !step.active) return null

						let productName = step.selected && step.selected.name
						// let productDescription = ''

						if (step.slug === 'led_strips' || step.slug === 'profiles') {
							let product = step.selected

							switch (attributeInfo) {
								case 'led_pro':
									if (product) {
										productName = typeof product.name_ledpro === 'string' ? product.name_ledpro : product.name
										// productDescription = typeof product.description_ledpro === 'string' ? product.description_ledpro : product.description
									}
									break
								case 'aeoss':
									if (product) {
										productName = product.name
										// productDescription = product.description
									}
									break
								default:
									if (product) {
										productName = product.name
										// productDescription = product.description
									}
							}
						}

						return (
							<li key={i} className={`side-preview-option ${step.previewSpace ? 'space-top' : ''}`}>
								{slugToIcon(step.slug)}
								<span>
									<span className="side-preview-name">{step.name}:</span>
									<span className="side-preview-value">{productName}</span>
								</span>
								{step.selected && step.selected.info && <span className="side-preview-info">{step.selected.info}</span>}
							</li>
						)
					})}
				<li className={`side-preview-option space-top`}>
					<div className="side-preview-icon">
						<div style={{ fontSize: '1.75em' }}>
							<FontAwesomeIcon icon={faBolt} />
						</div>
					</div>
					<span>
						<span className="side-preview-name">Volt:</span>
						<span className="side-preview-value">{powerInfo && powerInfo.volt}</span>
					</span>
				</li>
				<li className={`side-preview-option`}>
					<div className="side-preview-icon">
						<div style={{ fontSize: '1.75em' }}>
							<FontAwesomeIcon icon={faBolt} />
						</div>
					</div>
					<span>
						<span className="side-preview-name">Watt:</span>
						<span className="side-preview-value">{powerInfo && powerInfo.watt}</span>
					</span>
				</li>
			</ul>

			<div className="price-preview">
				<div className="side-preview-option">
					<div className="side-preview-icon">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="2em" height="2em">
							<path fill="none" d="M0 0h24v24H0z" />
							<path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1.95-9H15v2h-4.95a2.5 2.5 0 0 0 4.064 1.41l1.7 1.133A4.5 4.5 0 0 1 8.028 13H7v-2h1.027a4.5 4.5 0 0 1 7.788-2.543L14.114 9.59A2.5 2.5 0 0 0 10.05 11z" />
						</svg>
					</div>
					<b>Prezzo totale: {price} €</b>
				</div>
			</div>
		</>
	)
}
